<template>
    <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="row row-sm mg-t-10 mg-b-10">
            <div class="col-9">
                <h2 class="az-content-title tx-24 mg-b-5">Decentralized Identifier</h2>
            </div>
        </div>
        <p class="mg-b-25">
            Decentralized Identifier is a new form of identity, one that leverages blockchain technology and open standards ensuring
            self-ownership and censorship resistance — that are difficult to achieve with existing systems.
        </p>

        <div class="row row-sm form-group mg-t-10">
            <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                <select @change="getDIDClaims()" aria-hidden="true" class="form-control select2 select2-hidden-accessible rounded" data-select2-id="1" data-vv-as="Organization address country"
                        data-vv-scope="step2" name="org_country" tabindex="-1" v-model="selected_catalog">
                    <option :value="null" label="Choose Catalog"></option>
                    <option v-bind:key="index" v-bind:value="catalog.catalog" v-for="(catalog, index) in catalogs">{{catalog.name}}</option>
                </select>
            </div>
        </div>
        <template v-if="removeLoader">
            <div class="card widget-inline">
                <div class="card-body p-0 d-none d-sm-block">
                    <div class="row no-gutters">
                        <div class="col-sm-6 col-xl-4">
                            <div class="card shadow-none m-0">
                                <div class="card-body text-center">
                                    <i class="fas fa-fingerprint text-primary" style="font-size: 24px;"></i>
                                    <h3><span>{{dids_bkp.length}}</span></h3>
                                    <p class="text-muted font-15 mb-0">Total DIDs</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-xl-4">
                            <div class="card shadow-none m-0 border-left">
                                <div class="card-body text-center">
                                    <i class="fas fa-clipboard-list text-muted" style="font-size: 24px;"></i>
                                    <h3><span>{{did_with_claims_bkp.length}}</span></h3>
                                    <p class="text-muted font-15 mb-0">DID with Claims</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-xl-4">
                            <div class="card shadow-none m-0 border-left">
                                <div class="card-body text-center">
                                    <i class="fas fa-thumbs-up text-success" style="font-size: 24px;"></i>
                                    <h3><span>{{total_did_attestations}}</span></h3>
                                    <p class="text-muted font-15 mb-0">DID with Attestations</p>
                                </div>
                            </div>
                        </div>
                    </div> <!-- end row -->
                </div>
            </div> <!-- end card-box-->
            <div class="card card-dashboard-pageviews mg-t-30">
                <div class="card-header">
                    <div aria-orientation="horizontal" class="nav nav-pills" id="h-pills-tab" role="tablist">
                        <a :class="{active: activeTab === tab.index}"
                           :key="index" @click="navigate(tab.index)"
                           aria-selected="true" class="nav-link" data-toggle="pill" v-bind:href="'#'+tab.name" v-for="(tab, index) in tabs">{{tab.label}}</a>
                    </div>
                </div>
                <div class="card-body">
                    <div class="tab-content mg-t-20" id="h-pills-tabContent">
                        <div :class="{'d-none': activeTab !== 0}" id="did">
                            <div class="row mg-t-20 mg-b-20">
                                <div class="col-md-6">
                                    <v-md-date-range-picker
                                            :auto-apply="false"
                                            :opens="opens"
                                            :show-activator-bar="false"
                                            :show-activator-label="false"
                                            :show-custom-range-label="value"
                                            @change="change"
                                            show-year-select>
                                    </v-md-date-range-picker>
                                </div>
                                <div class="col-md-6">
                                    <div class="row row-xs wd-xl-50p">
                                        <div class="col-sm-5 col-md-5">
                                            <button @click="getFilteredDids" class="btn btn-dark btn-block rounded" style="height: 32px">Go</button>
                                        </div>
                                        <div class="col-sm-6 col-md-6 mg-t-10 mg-sm-t-0">
                                            <button @click="resetFilteredDids" class="btn btn-secondary btn-block rounded" style="height: 32px">Reset</button>
                                        </div>
                                        <div class="col-sm-1 col-md-1 mg-t-10 mg-sm-t-0 text-right">
                                            <button @click="exportDids()" class="btn btn-info btn-icon rounded"><i class="typcn typcn-export-outline"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover mg-b-0">
                                    <thead>
                                    <tr>
                                        <th>DID</th>
                                        <th>Label</th>
                                        <th>Created On</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr @click="viewDid(did.catalog, did.did)" class="pointer" v-for="did in dids">
                                        <td>{{did.did | did | truncate(20, '....')}}</td>
                                        <td>{{did.short_name}}</td>
                                        <td>{{did.created | date}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>
                            <pagination :page="did_page" :total="total_dids" @pagechange="handlePagerChange"/>
                        </div>
                        <div :class="{'d-none': activeTab !== 1}" id="did_with_claims">
                            <div class="row mg-b-20">
                                <div class="col-md-6">
                                    <v-md-date-range-picker
                                            :auto-apply="false"
                                            :opens="opens"
                                            :show-activator-bar="false"
                                            :show-activator-label="false"
                                            :show-custom-range-label="value"
                                            @change="changeClaimIssueDate"
                                            show-year-select>
                                    </v-md-date-range-picker>
                                </div>
                                <div class="col-md-6">
                                    <div class="row row-xs wd-xl-50p">
                                        <div class="col-sm-5 col-md-5">
                                            <button @click="getFilteredDidWithClaims" class="btn btn-dark btn-block rounded" style="height: 32px">Go</button>
                                        </div>
                                        <div class="col-sm-6 col-md-6 mg-t-10 mg-sm-t-0">
                                            <button @click="resetFilteredDidWithClaims" class="btn btn-secondary btn-block rounded" style="height: 32px">Reset</button>
                                        </div>
                                        <div class="col-sm-1 col-md-1 mg-t-10 mg-sm-t-0 text-right">
                                            <button @click="exportDidWithClaims()" class="btn btn-info btn-icon rounded"><i class="typcn typcn-export-outline"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive" v-if="did_with_claims.length>0">
                                <table class="table table-hover mg-b-0">
                                    <thead>
                                    <tr>
                                        <th>DID</th>
                                        <th>Label</th>
                                        <th>No of Claims</th>
                                        <th>Last Claim Issued On</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr @click="viewDid(did.catalog, did.did)" class="pointer" v-for="did in did_with_claims">
                                        <td>{{did.did | did | truncate(20, '....')}}</td>
                                        <td>{{did.short_name}}</td>
                                        <td>{{did.claim_count}}</td>
                                        <td>{{did.last_claim_issued | date}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <hr>
                                <pagination :page="did_claims_page" :total="total_did_claims" @pagechange="handleDidClaimPagerChange"/>
                            </div>
                            <template v-else>
                                <h5 class="text-center text-muted mt-5 mb-5">
                                    The list of DIDs which have at least one claim not found
                                </h5>
                            </template>
                        </div>
                        <div :class="{'d-none': activeTab !== 2}" id="did_with_attestations">
                            <div class="table-responsive" v-if="did_with_attestations.length>0">
                                <table class="table table-hover mg-b-0">
                                    <thead>
                                    <tr>
                                        <th>DID</th>
                                        <th>Label</th>
                                        <th>No of Attestations</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr @click="viewDid(did.catalog, did.did)" class="pointer" v-for="did in did_with_attestations">
                                        <td>{{did.did | did | truncate(20, '....')}}</td>
                                        <td>{{did.short_name}}</td>
                                        <td>{{did.attestation_count}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <hr>
                                <pagination :page="did_attestations_page" :total="total_did_attestations" @pagechange="handleDidAttestationPagerChange"/>
                            </div>
                            <template v-else>
                                <h5 class="text-center text-muted mt-5 mb-5">
                                    The list of DIDs which have at least one attestation not found
                                </h5>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="d-flex justify-content-center mg-t-40" v-else>
            <div class="spinner-border avatar-md text-primary" role="status"></div>
        </div>
    </div>
</template>

<script>
    import Pagination from '../../Pagination';
    import XLSX from 'xlsx';
    import moment from 'moment';

    export default {
        name: 'Home',
        components: {Pagination},
        data() {
            return {
                catalogs: [],
                selected_catalog: null,
                dids: [],
                dids_bkp: [],
                filtered_dids_bkp: [],
                claim_calls: [],
                removeLoader: false,
                tabs: [
                    {
                        index: 0,
                        name: 'did',
                        label: 'DIDs'
                    },
                    {
                        index: 1,
                        name: 'did_with_claims',
                        label: 'DID with Claims'
                    },
                    {
                        index: 2,
                        name: 'did_with_attestations',
                        label: 'DID with Attestations'
                    },
                ],
                activeTab: 0,
                did_with_claims: [],
                did_with_claims_bkp: [],
                filtered_did_with_claims_bkp: [],
                did_with_attestations: [],
                did_with_attestations_bkp: [],
                did_page: 0,
                did_per_page: localStorage.getItem('pageSize') || 5,
                total_dids: 0,
                did_claims_page: 0,
                did_claims_per_page: localStorage.getItem('pageSize') || 5,
                total_did_claims: 0,
                did_attestations_page: 0,
                did_attestations_per_page: localStorage.getItem('pageSize') || 5,
                total_did_attestations: 0,
                start_date: null,
                end_date: null,
                opens: 'left',
                value: true,
                claim_issue_start_date: null,
                claim_issue_end_date: null,
                is_did_filtered: false,
                is_did_with_claims_filtered: false
            }
        },
        mounted() {
            this.init();
            navigator.geolocation.getCurrentPosition(function (location) {
                // console.log(location.coords.latitude);
                // console.log(location.coords.longitude);
                // console.log(location.coords.accuracy);
            });
        },
        methods: {
            async init() {
                await this.getCatalogs();
            },
            navigate(index) {
                this.activeTab = index;
            },
            async getDIDClaims() {
                this.dids = [];
                this.claim_calls = [];
                this.did_with_claims_bkp = [];
                this.did_with_attestations_bkp = [];
                try {
                    this.removeLoader = false;
                    await this.getDids();
                    await this.getClaimCount();
                } catch (e) {
                } finally {
                    this.removeLoader = true;
                }
            },
            async getCatalogs() {
                let {data} = await this.$identity.get('identity/catalogs?page=0&per_page=10000');
                this.catalogs = data.catalogs.reverse();
                this.selected_catalog = this.catalogs.length > 0 ? this.catalogs[0].catalog : null;
                await this.getDIDClaims();
            },
            async getDids() {
                if (this.selected_catalog) {
                    try {
                        let {data} = await this.$identity.get(`/identity/catalogs/${this.selected_catalog}/dids?page=0&per_page=10000`);
                        this.total_dids = data.total;
                        this.dids_bkp = data.dids.reverse();
                        data.dids.forEach(did => {
                            this.claim_calls.push(this.getClaims(did.did))
                        });
                        this.didPaginate()
                    } catch (e) {

                    } finally {
                    }
                }
            },
            getFilteredDids() {
                this.is_did_filtered = true;
                let self = this;
                this.did_page = 0;
                this.did_per_page = localStorage.getItem('pageSize') || 5;
                this.filtered_dids_bkp = this.dids_bkp.filter(item => {
                    let date = moment(item.created);
                    let start_date = moment.utc(self.start_date);
                    let end_date = moment.utc(self.end_date);
                    return date.isBetween(start_date, end_date) || date.isSame(start_date) || date.isSame(end_date);
                });
                this.total_dids = this.filtered_dids_bkp.length;
                this.didPaginate();
            },
            didPaginate() {
                let start = (this.did_page) * this.did_per_page;
                let end = Number(start) + Number(this.did_per_page);
                if (this.is_did_filtered) {
                    this.dids = this.filtered_dids_bkp.slice(start, end);
                } else {
                    this.dids = this.dids_bkp.slice(start, end);
                }
            },
            async handlePagerChange(e) {
                this.did_page = e.page;
                this.did_per_page = e.per_page;
                this.didPaginate();
            },
            async getClaims(did) {
                let {data} = await this.$identity.get(`/identity/dids/${did}/claims?page=0&per_page=10000`);
                let claims = data.claims;
                let attestations = claims.filter(c => c.attestations_count > 0);
                return {
                    last_claim_issued: claims.length > 0 ? claims[claims.length - 1].created : null,
                    claims: claims.length,
                    attestations: attestations.length,
                    claim_count: claims.length > 0 ? 1 : 0,
                    attestation_count: attestations.length > 0 ? 1 : 0
                };
            },
            async getClaimCount() {
                let result = await Promise.all(this.claim_calls);
                this.did_with_claims_bkp = this.dids_bkp.map((filtered_did, i) => ({
                    ...filtered_did,
                    last_claim_issued: result[i].last_claim_issued,
                    claim_count: result[i].claims
                })).filter((did, i) => result[i].claims > 0);
                this.total_did_claims = this.did_with_claims_bkp.length;
                this.didWithClaimsPaginate();
                this.did_with_attestations_bkp = this.dids_bkp.map((filtered_did, i) => ({
                    ...filtered_did,
                    attestation_count: result[i].attestations
                })).filter((did, i) => result[i].attestations > 0);
                this.total_did_attestations = this.did_with_attestations_bkp.length;
                this.didWithAttestationPaginate();
            },
            getFilteredDidWithClaims() {
                this.is_did_with_claims_filtered = true;
                let self = this;
                this.did_claims_page = 0;
                this.did_claims_per_page = localStorage.getItem('pageSize') || 5;
                this.filtered_did_with_claims_bkp = this.did_with_claims_bkp.filter(item => {
                    let date = moment(item.last_claim_issued);
                    let start_date = moment.utc(self.claim_issue_start_date);
                    let end_date = moment.utc(self.claim_issue_end_date);
                    return date.isBetween(start_date, end_date) || date.isSame(start_date) || date.isSame(end_date);
                });
                this.total_did_claims = this.filtered_did_with_claims_bkp.length;
                this.didWithClaimsPaginate();
            },
            didWithClaimsPaginate() {
                let start = (this.did_claims_page) * this.did_claims_per_page;
                let end = Number(start) + Number(this.did_claims_per_page);
                if (this.is_did_with_claims_filtered) {
                    this.did_with_claims = this.filtered_did_with_claims_bkp.slice(start, end);
                } else {
                    this.did_with_claims = this.did_with_claims_bkp.slice(start, end);
                }
            },
            didWithAttestationPaginate() {
                let start = (this.did_attestations_page) * this.did_attestations_per_page;
                let end = Number(start) + Number(this.did_attestations_per_page);
                this.did_with_attestations = this.did_with_attestations_bkp.slice(start, end);
            },
            async handleDidClaimPagerChange(e) {
                this.did_claims_page = e.page;
                this.did_claims_per_page = e.per_page;
                this.didWithClaimsPaginate();
            },
            async handleDidAttestationPagerChange(e) {
                this.did_attestations_page = e.page;
                this.did_attestations_per_page = e.per_page;
                this.didWithAttestationPaginate();
            },
            viewDid(catalog, did) {
                this.$router.push({name: 'viewdid', params: {catalogid: catalog, did: did}});
            },
            change(e) {
                this.start_date = e[0].toDate();
                this.end_date = e[1].toDate();
            },
            changeClaimIssueDate(e) {
                this.claim_issue_start_date = e[0].toDate();
                this.claim_issue_end_date = e[1].toDate();
            },
            resetFilteredDids() {
                this.total_dids = this.dids_bkp.length;
                this.is_did_filtered = false;
                this.didPaginate()
            },
            resetFilteredDidWithClaims() {
                this.total_did_claims = this.did_with_claims_bkp.length;
                this.is_did_with_claims_filtered = false;
                this.didWithClaimsPaginate()
            },
            exportDids() {
                let workbook = XLSX.utils.book_new();
                let worksheet = null;
                if (this.is_did_filtered) {
                    let filtered_dids_bkp = this.filtered_dids_bkp.map(did => ({
                        Catalog: this.catalogs.find(catalog => catalog.catalog === did.catalog).name,
                        DID: 'did:bws:' + did.did.substring(2, did.did.length - 2),
                        Label: did.short_name,
                        'Created At': moment(Date.parse(did.created)).format('MMMM Do YYYY')
                    }))
                    console.log(filtered_dids_bkp);
                    worksheet = XLSX.utils.json_to_sheet(filtered_dids_bkp);
                } else {
                    let dids_bkp = this.dids_bkp.map(did => ({
                        Catalog: this.catalogs.find(catalog => catalog.catalog === did.catalog).name,
                        DID: 'did:bws:' + did.did.substring(2, did.did.length - 2),
                        Label: did.short_name,
                        'Created At': moment(Date.parse(did.created)).format('MMMM Do YYYY')
                    }))
                    console.log(dids_bkp);
                    worksheet = XLSX.utils.json_to_sheet(dids_bkp);
                }
                XLSX.utils.book_append_sheet(workbook, worksheet, 'DIDs');
                XLSX.writeFile(workbook, 'DID.xlsx');
            },
            exportDidWithClaims() {
                let workbook = XLSX.utils.book_new();
                let worksheet = null;
                if (this.is_did_with_claims_filtered) {
                    let filtered_did_with_claims_bkp = this.filtered_did_with_claims_bkp.map(did => ({
                        Catalog: this.catalogs.find(catalog => catalog.catalog === did.catalog).name,
                        DID: 'did:bws:' + did.did.substring(2, did.did.length - 2),
                        Label: did.short_name,
                        'Last Claim Issued On': moment(Date.parse(did.last_claim_issued)).format('MMMM Do YYYY')
                    }))
                    worksheet = XLSX.utils.json_to_sheet(filtered_did_with_claims_bkp);
                } else {
                    let did_with_claims_bkp = this.did_with_claims_bkp.map(did => ({
                        Catalog: this.catalogs.find(catalog => catalog.catalog === did.catalog).name,
                        DID: 'did:bws:' + did.did.substring(2, did.did.length - 2),
                        Label: did.short_name,
                        'Last Claim Issued On': moment(Date.parse(did.last_claim_issued)).format('MMMM Do YYYY')
                    }))
                    worksheet = XLSX.utils.json_to_sheet(did_with_claims_bkp);
                }
                XLSX.utils.book_append_sheet(workbook, worksheet, 'DID_with_claims');
                XLSX.writeFile(workbook, 'DID with Claims.xlsx');
            },
            exportDidWithAttestations() {
                let workbook = XLSX.utils.book_new();
                let worksheet = XLSX.utils.json_to_sheet(this.did_with_attestations_bkp);
                XLSX.utils.book_append_sheet(workbook, worksheet, 'DID_with_attestations');
                XLSX.writeFile(workbook, 'DID with Attestations.xlsx');
            }
        }
    }
</script>

<style scoped>

</style>