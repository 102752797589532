<template>
    <div class="row">
        <div class="col-sm-12 col-md-6 d-flex mt-1">
            <label class="mr-2 mt-1">Per Page</label>
            <select class="custom-select custom-select-sm w-auto py-0" v-model="pageSize">
                <option v-for="size in pageSizes" :value="size">{{size}}</option>
            </select>
            <label class="ml-2 mt-1">Items out of <b>{{total}}</b> entries</label>
        </div>
        <div class="col-sm-12 col-md-6 mt-1">
            <ul class="pagination mg-b-0 float-md-right">
                <li class="page-item" @click="prev" title="Previous">
                    <a class="page-link" href="javascript:void(0)">
                        <i class="fa fa-chevron-left"/>
                    </a>
                </li>

                <template v-for="p in pages">
                    <li class="page-item" :class="{ 'active': p === page }" @click="goTo(p)" v-if="isEdge(p)">
                        <a class="page-link" href="javascript:void(0);">{{p + 1}}</a>
                    </li>

                    <li class="page-item" :class="{ 'active': p === page }" @click="goTo(p)" v-else-if="isClose(p)">
                        <a class="page-link" href="javascript:void(0);">{{p + 1}}</a>
                    </li>

                    <li class="page-item" v-else-if="isNextToClose(p)">
                        <a class="page-link disabled" href="javascript:void(0);">
                            <i class="fa fa-ellipsis-h"/>
                        </a>
                    </li>
                </template>

                <li class="page-item" @click="next" :title="'Next'">
                    <a class="page-link" href="javascript:void(0)">
                        <i class="fa fa-chevron-right"/>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    /**
     * page: Integer; Current page index (index starts from 0)
     * total: Integer; Total items in db
     * count: Integer; Current page item count
     * pageRange: Integer; Number of pages should show beside each side of selected page; default 1, i.e. 1 page before and after selected page
     * edgeRange: Integer; Number of pages should show from edges; default 1, i.e. 1 page after '<' and 1 page before '>'
     */
    export default {
        name: "Pagination",
        props: ["page", "total", "count", "pageRange", "edgeRange"],
        data() {
            return {
                pageSizes: [5, 10, 20, 50],
                pageSize: localStorage.getItem('pageSize') || 5,
                pages: [0],
            }
        },
        watch: {
            pageSize: function(n, o) {
                localStorage.setItem('pageSize', n)
                this.setPages();
                this.$emit('pagechange', {page: 0, per_page: this.pageSize})
            },
            total: function(n, o) {
                this.setPages();
            }
        },
        mounted() {
            this.setPages();
        },
        methods: {
            setPages() {
                this.pages = [];
                let x      = Math.ceil(this.total / this.pageSize);
                for(let i = 0; i < x; i++) {
                    this.pages.push(i);
                }
            },
            goTo(page) {
                this.$emit('pagechange', {page: page, per_page: this.pageSize});
            },
            prev() {
                if(this.page > 0) {
                    this.$emit('pagechange', {page: this.page - 1, per_page: this.pageSize});
                }
            },
            next() {
                if(this.page < this.pages[this.pages.length - 1]) {
                    this.$emit('pagechange', {page: this.page + 1, per_page: this.pageSize});
                }
            },
            isClose(i) {
                let indices = [this.page];
                let delta   = this.pageRange !==  null && this.pageRange >= 0 ? this.pageRange : 1;
                for(let i = 0; i < delta; i++) {
                    indices.push(this.page - (i + 1));
                    indices.push(this.page + (i + 1));
                }

                return indices.includes(i);
            },
            isNextToClose(i) {
                let delta   = this.pageRange !==  null && this.pageRange >= 0 ? this.pageRange : 1;

                return i === (this.page - (delta + 1)) || i === this.page + (delta + 1);
            },
            isEdge(i) {
                let indices = [];
                let delta   = this.edgeRange !==  null && this.edgeRange >= 0 ? this.edgeRange : 1;
                for(let i = 0; i < delta; i++) {
                    indices.push(i);
                    indices.push(this.pages.length - i - 1);
                }

                return indices.includes(i);
            }
        }
    }
</script>

<style scoped>

</style>